<template>
  <div class="wrap">
    <el-button @click="dialogVisible = true">创建海报</el-button>
    <div class="wrap_box">
      <div v-for="(item, index) in poster" :key="index">
        <img :src="item.file_id" alt />
        <el-button
          type="danger"
          class="del"
          size="small"
          @click="del(item, index)"
          v-if="item.file_id != 'undefined'"
          >删除</el-button
        >
      </div>
    </div>
    <el-dialog title="创建海报" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-upload
          :limit="3"
          action="/api/kapin_ee/user/v1/files_upload/"
          list-type="picture-card"
          :on-success="handlePic"
          :on-remove="handleRemove"
          :file-list="img_list_1"
          multiple
          :before-upload="beforeUploadVideoImg"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <!-- :visible.sync="dialogVisible" -->
        <el-dialog>
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
        <el-button type="primary" @click="upload_pic">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  poster_list,
  poster_del,
  poster_upload,
} from '@/api/hr24_api/api_all.js'
export default {
  data() {
    return {
      poster: [],
      dialogImageUrl: '',
      // dialogVisible: false,
      dialogVisible: false,
      img_list: [],
      img_list_1: [],
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    quxiao() {
      this.dialogVisible = false
      this.file_id = ''
      this.file_url = ''
      this.image_id = ''
      this.image_url = ''
      this.is_video = false
      this.img_list = []
      this.img_list_1 = []
    },
    init() {
      poster_list().then((res) => {
        if (!res.code) {
          console.log(res)
          this.poster = res.data.poster_list
          this.quxiao()
        }
      })
    },

    del(e, index) {
      poster_del(e.id).then((res) => {
        if (!res.code) {
          this.poster.splice(index, 1)
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    beforeUploadVideoImg(file) {
      console.log(file)
      var fileSize = file.size / 1024 / 1024 < 2
      if (!fileSize) {
        this.$message.warning('海报大小不能超过2MB')
        return false
      }
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.warning('上传文件类型必须是图片!')
      }
      return fileSize && isImage
    },
    upload_pic() {
      if (this.img_list.length == 0) {
        this.$message.warning('请上传海报')
        return false
      }
      this.dialogVisible = false
      poster_upload(this.img_list).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg)
          this.init()
          this.quxiao()
        } else {
          this.$message.error(res.msg)
        }
        console.log(res)
      })
    },

    handleRemove(file, fileList) {
      let dialogImageUrl = []
      console.log(file, fileList)
      fileList.map((item) => {
        dialogImageUrl.push(item.response.file_id)
        this.dialogImageUrl = dialogImageUrl
      })
    },
    handlePic(file) {
      console.log(file)
      this.img_list.push(file.file_id)
      console.log(this.dialogImageUrl, file, 'zhehs')
    },
  },
}
</script>

<style scoped lang="scss">
.main .wrap {
  padding: 0;
  padding: 20px 0;
}
.wrap_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

/deep/.el-upload--picture-card:hover,
.el-upload:focus {
  border: 1px dashed $main2_color;
}

/deep/.el-upload--picture-card {
  margin-bottom: 10px;
}

.wrap_box > div {
  width: 13%;
  // min-height: 260px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

img {
  width: 100%;
}
.del {
  width: 100%;
}
</style>
